 <style>
    .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 3.4499999999999993rem;
        color-adjust: exact;
    }
  </style>

<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
         <b-col
        xl="12"
      >
        <b-form-group
          label="No Register"
          label-for="no_register"
        >
          <b-form-input
            id="no_register"
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_registers"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="12"
        class="mt-2"
      >
        <b-form-group
          label-for="tanggal"
        >
          <label >Tanggal</label>
          <b-form-datepicker v-model="localData.tanggal_dokumen_berita_acara" locale="id" class="mb-2"></b-form-datepicker>
        </b-form-group>
       </b-col>
       <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Jenis Dokumen</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="jenisDokumenOptions"
            v-model="localData.jenis_dokumen_berita_acara_id"
            :disabled="true"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-2"
        >
            <b-button class="btn" variant="outline-primary" :disabled="localData.jenis_dokumen_berita_acara_id === null" @click="downloadTemplate()">Unduh Template</b-button>
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-2"
        >
            <b-media no-body>
              <b-media-body class="mt-60 ml-60">
                <label>File</label>
                <div class="d-flex" style="width: 100%;">
                  <b-form-file
                    v-if="!$route.params.readonly"
                    v-model="localData.file"
                    placeholder="Masukkan File"
                    class="mr-1"
                  >
                  </b-form-file>
                  <b-button class="btn" variant="outline-success" @click="downloadFile" v-if="localData.berita_acara">Download</b-button>
                </div>
                <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
              </b-media-body>
            </b-media>
        </b-col>
         <b-col
            cols="12"
            md="12"
            class="mt-2"
          >
              <div class="d-flex justify-content-between mb-3">
                <b-button class="btn" variant="primary" @click="updateData">Simpan</b-button>
                <!-- <b-button class="btn mr-1" v-b-modal.modal_confirm_pemeriksaan_lanjutan variant="primary">Teruskan ke MKH</b-button> -->
              </div>
              <!-- <div>
                  <b-modal id="modal_confirm_pemeriksaan_lanjutan" title="Konfirmasi" centered hide-footer>
                    <div class="d-flex justify-content-center" >
                      <h4 class="my-4" style="text-align: center;">Apakah anda yakin teruskan ke MKH?</h4>
                    </div>
                    <div class="d-flex justify-content-end">
                      <b-form-group>
                        <b-button class="btn mr-1" variant="primary" @click="update('pemeriksaan-lanjutkan')">Ya</b-button>
                      </b-form-group>
                      <b-form-group>
                        <b-button class="btn" variant="outline-primary" ref="btnClose" @click="$root.$emit('bv::hide::modal', 'modal_confirm_pemeriksaan_lanjutan')">Tidak</b-button>
                      </b-form-group>
                    </div>
                  </b-modal>
              </div> -->
          </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker, BFormTimepicker, BFormCheckboxGroup, BModal,BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
    tambahAgama,
    downloadTemplate,
    uploadFilePlenoJadwal,
    downloadFilePlenoJadwal,
    updateBeritaAcaraPleno,
    updateVerifikasi,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckboxGroup,
    BModal,
    BFormGroup,
  },
  data() {
    return {
      error: {},
      selected: [],
        options: [
          { item: 'Tidak Terapat Pelanggaran KEPPPH', name: 'Tidak Terapat Pelanggaran KEPPPH' },
          { item: 'Bukan Wewenang Komisi Yudisial', name: 'Bukan Wewenang Komisi Yudisial' },
          { item: 'Tidak Terdapat Cukup Bukti', name: 'Tidak Terdapat Cukup Bukti' },
          { item: 'Diajukan Dalam Sidang Pleno', name: 'Diajukan Dalam Sidang Pleno' },
          { item: 'Penanggung jawab penanganan laporan anggota KY', name: 'Penanggung jawab penanganan laporan anggota KY' },
          // { item: 'D', name: 'Option C', notEnabled: true },
          // { item: { d: 1 }, name: 'Option D' }
        ]
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    jenisDokumenOptions: {},
    localData: {},
  },
  methods: {
    async update(params) {
      switch (params) {
        case 'pemeriksaan-lanjutan':
          this.localData.jenis_cta = "pemeriksaan-lanjutan";
          break;
      }
      
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Pleno Jadwal',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$router.push({name: 'jadwal-pleno'})
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Pleno Jadwal',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    async downloadTemplate()
    {
      const result = await downloadTemplate(this.localData.jenis_dokumen_berita_acara_id, this.localData.laporan_id)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', 'Berita-Acara-Sidang.docx')
      document.body.appendChild(link)
      link.click()
    },
     async downloadFile()
    {
      const result = await downloadFilePlenoJadwal(this.localData.berita_acara)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', this.localData.berita_acara)
      document.body.appendChild(link)
      link.click()
    },
    async updateData()
    {
      if(this.localData.file !== undefined)
      {
        const responseUpload = await uploadFilePlenoJadwal(this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        this.localData.berita_acara = responseUpload.data.filename
      }
      const response = await updateBeritaAcaraPleno(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Berita Acara',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Berita Acara',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
