<template>
  <b-row>
    <b-col cols="12">
      <form-wizard-number 
      :localData="localData" 
      :tenagaAhliOption="tenagaAhliOption.options"
      :penyajiOption="penyajiOption.options"
      :timPemeriksaOption="timPemeriksaOption.options"
      :komisionerOption="komisionerOption.options" 
      :dataPutusanPleno="dataPutusanPleno.data_no_register"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import FormWizardNumber from './FormWizardNumber.vue'
import {
  getPlenoJadwalById,
  getDataBuatJadwalSidangPleno,
} from '@/connection-api/master'
import {
  generateNomorPenerimaan,
  createDataLaporan,
  updateDataKerjasama,
  getDetailDataLaporan,
} from '@/connection-api/laporan'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    FormWizardNumber,
  },
  data() {
    return {
      localData: {
        jenis_penyampaian_id: null,
        jenis_pihak_terlibat_id: null,
        jenis_dokumen_id: null,
        nota_kesepahaman_terkait: null,
      },
      tokenCreate: '',
      jenisPenyampaianOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Penyampaian --' },
        ],
      },
      kantorPenghubungOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kantor Penghubung --' },
        ],
      },
      jenisPerkaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Perkara --' },
        ],
      },
      lingkunganPeradilanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Lingkungan Peradilan --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi Lingkungan Peradilan --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kab/Kota Lingkungan Peradilan --' },
        ],
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      tenagaAhliOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tenaga Ahli --' },
        ],
      },
      penyajiOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Penyaji --' },
        ],
      },
      timPemeriksaOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tim Pemeriksa --' },
        ],
      },
      komisionerOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Nama --' },
        ],
      },
      dataJadwalSidangPleno: {},
      dataPutusanPleno: {},
    }
  },
  async mounted() {
    const responseDetailLaporan = await getPlenoJadwalById(this.$route.params.id)
    this.localData = responseDetailLaporan.data.data
    this.detailJadwalPleno(this.localData.laporan_selected)
  },
  methods: {
    async detailJadwalPleno(selectedLaporan) {
      const response = await getDataBuatJadwalSidangPleno(selectedLaporan)
        if(response.data.success == true) {
          this.tenagaAhliOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Tenaga Ahli --' },
            ],
          }
          this.penyajiOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Penyaji --' },
            ],
          }
          this.timPemeriksaOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Tim Pemeriksa --' },
            ],
          }
          this.komisionerOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Nama --' },
            ],
          }
          
          this.localData.dataJadwalSidangPleno = response.data.data.data_no_register
          this.dataPutusanPleno = response.data.data
          this.mappingSelectOption(response.data.data.tenaga_ahli, this.tenagaAhliOption.options)
          this.mappingSelectOption(response.data.data.penyaji, this.penyajiOption.options)
          this.mappingSelectOption(response.data.data.tim_pemeriksa, this.timPemeriksaOption.options)
          this.mappingSelectOption(response.data.data.komisioner, this.komisionerOption.options)
        }
    },
     mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Laporan',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
}

</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
