<style>
.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 3.4499999999999993rem;
    color-adjust: exact;
}
</style>

<template>
<b-row class="mb-2">
<b-col
  cols="12"
  md="12"
  class="mb-md-0 mt-4"
>
  <b-card no-body>
    <b-tabs content-class="mt-3">
      <b-tab v-for="(putusanPleno, index) in dataPutusanPleno" :key="putusanPleno.id" :title="putusanPleno.no_register">
        <b-card-body>
          <b-row class="mb-2">
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mt-2"
            >
              <label>Putusan Berdasarkan Nomor Register</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="w-100"
                  :reduce="val => val.value"
                  :options="penetapanOptions"
                  v-model="putusanPleno.putusan_id"
                />
                <small class="mt-1" style="color: red;" v-if="error.putusan_id">{{error.message}}</small>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mt-4"
            >
            <div class="d-flex" v-if="putusanPleno.putusan_id === 6">
              <label for="">Kesimpulan</label>
              <b-form-checkbox-group
                  v-model="putusanPleno.kesimpulan"
                  :options="optionsBTD"
                  class="mb-3 ml-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  stacked
                ></b-form-checkbox-group>
            </div>
            <div class="d-flex" v-if="putusanPleno.putusan_id === 5">
              <label for="">Kesimpulan</label>
              <b-form-checkbox-group
                  v-model="putusanPleno.kesimpulan"
                  :options="optionsBTD2"
                  class="mb-3 ml-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  stacked
                ></b-form-checkbox-group>
            </div>
            <div class="d-flex" v-if="putusanPleno.putusan_id === 7 || putusanPleno.putusan_id === 8 || putusanPleno.putusan_id === 9">
              <label for="">Kesimpulan</label>
              <b-form-checkbox-group
                  v-model="putusanPleno.kesimpulan"
                  :options="optionsBTD3"
                  class="mb-3 ml-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  stacked
                ></b-form-checkbox-group>
            </div>
            <div v-if="putusanPleno.putusan_id === 6 || putusanPleno.putusan_id === 5">
                <label>Disenting Opinion </label>
                <b-form-textarea min-rows="3" v-model="putusanPleno.disenting_opinion"></b-form-textarea>
            </div>
            <div class="mt-1 mb-3" v-if="putusanPleno.putusan_id === 6 || putusanPleno.putusan_id === 5">
              <label>Concurring Opinion </label>
              <b-form-textarea min-rows="3" v-model="putusanPleno.concurring_opinion"></b-form-textarea>
            </div>
            <div class="d-flex" v-if="putusanPleno.putusan_id === 1">
              <label for="">Kesimpulan</label>
              <b-form-checkbox-group
                  v-model="putusanPleno.kesimpulan"
                  :options="optionsBD"
                  class="mb-3 ml-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  stacked
                ></b-form-checkbox-group>
            </div>
            <small class="mt-1" style="color: red;" v-if="error.tim_panel">{{error.message}}</small>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0"
              v-if="putusanPleno.putusan_id !== 9"
            >
              <label>Penanggung Jawab</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="w-100"
                  :reduce="val => val.value"
                  :options="komisionerOptions"
                  v-model="putusanPleno.penanggung_jawab_putusan_id"
                />
                <small class="mt-1" style="color: red;" v-if="error.penanggung_jawab_id">{{error.message}}</small>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mt-2"
              v-if="putusanPleno.putusan_id !== 9"
            >
              <label>Hasil</label>
                <b-form-textarea rows="6" v-model="putusanPleno.uraian_putusan"></b-form-textarea>
                <small class="mt-1" style="color: red;" v-if="error.tim_panel">{{error.message}}</small>
            </b-col>
            <!-- <b-col
              cols="12"
              md="12"
              class="mb-md-0 mt-2"
              v-if="putusanPleno.putusan_id !== 3"
            >
              <label>Pendapat Majelis</label>
                <b-form-textarea min-rows="3" v-model="putusanPleno.pendapat_majelis"></b-form-textarea>
                <small class="mt-1" style="color: red;" v-if="error.tim_panel">{{error.message}}</small>
            </b-col> -->

            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mt-2"
              v-if="putusanPleno.putusan_id === 9"
            >
              <label>Alasan</label>
                <b-form-textarea min-rows="3" v-model="putusanPleno.alasan"></b-form-textarea>
                <small class="mt-1" style="color: red;" v-if="error.alasan">{{error.message}}</small>
            </b-col>
            
            <!-- <b-col
              cols="12"
              md="12"
              class="mt-2"
              v-if="putusanPleno.putusan_id !== 3"
            >
                <b-media no-body>
                  <b-media-body>
                    <label>Upload Tanda Tangan Ketua</label>
                    <div class="d-flex" style="width: 100%;">
                      <b-form-file
                        placeholder="Masukkan File"
                        class="mr-1"
                        v-model="putusanPleno.file"
                      >
                      </b-form-file>
                      <b-button class="btn mr-1" variant="outline-success" v-if="putusanPleno.tanda_tangan != null" @click="download(putusanPleno.tanda_tangan)">Download</b-button>
                    </div>
                    <label class="ml-1" style="color: red;" >Ukuran file max 5mb</label>
                  </b-media-body>
                </b-media>
            </b-col> -->
            <b-col
              cols="12"
              md="12"
              class="mt-2"
            >
            <div>
                <b-modal id="modal_confirm_pemeriksaan_lanjutan_3" title="Konfirmasi" centered hide-footer>
                  <div class="d-flex justify-content-center" >
                    <h4 class="my-4" style="text-align: center;">Apakah anda yakin teruskan ke Pemeriksaan Lanjutan?</h4>
                  </div>
                  <div class="d-flex justify-content-end">
                    <b-form-group>
                      <b-button class="btn mr-1" variant="primary" @click="update('pemeriksaan-lanjutkan')">Ya</b-button>
                    </b-form-group>
                    <b-form-group>
                      <b-button class="btn" variant="outline-primary" ref="btnClose" @click="$root.$emit('bv::hide::modal', 'modal_confirm_pemeriksaan_lanjutan_3')">Tidak</b-button>
                    </b-form-group>
                  </div>
                </b-modal>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mt-2"
            >
            <div class="d-flex justify-content-begin mb-3 ml-2">
                <b-button class="btn" variant="primary" @click="$bvModal.show(`modal_konfirmasi_no_registrasi_tindakan_${index}`)">Simpan</b-button>
            </div>
            <div>
              <b-modal :id="`modal_konfirmasi_no_registrasi_tindakan_${index}`" centered title="Konfirmasi">
                <center>
                  <h4 class="my-2">Apakah anda yakin ingin akan melakukan putusan?</h4>
                </center>
                <template #modal-footer>
                  <b-button variant="primary" @click="updateTindakan(index)">Ya</b-button>
                  <b-button variant="secondary" @click="$bvModal.hide(`modal_konfirmasi_no_registrasi_tindakan_${index}`)">Tidak</b-button>
                </template>
              </b-modal>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="mb-md-0 mt-2"
          >
          <!-- <div class="d-flex justify-content-begin mb-3 ml-2">
              <b-button class="btn" variant="primary" @click="$bvModal.show(`modal_konfirmasi_redirect`)">Simpan</b-button>
          </div> -->
          <div>
            <b-modal :id="`modal_konfirmasi_redirect_${index}`" centered title="Konfirmasi">
              <center>
                <h4 class="my-2">Apakah anda ingin tetap dihalaman ini?</h4>
              </center>
              <template #modal-footer>
                <b-button variant="primary" @click="$bvModal.hide(`modal_konfirmasi_redirect_${index}`)">Ya</b-button>
                <b-button variant="secondary" @click="redirectF(putusanPleno.putusan_id)">Tidak</b-button>
              </template>
            </b-modal>
          </div>
        </b-col>
          </b-row>
        </b-card-body>
      </b-tab>
    </b-tabs>
  </b-card>
</b-col>
</b-row>
</template>

<script>
import {
BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker, BFormTimepicker, BFormCheckboxGroup, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
updateJadwalPlenoPutusan,
uploadFilePanelJadwal,
downloadFilePanelJadwal,
updateVerifikasi,
} from '@/connection-api/master'

export default {
components: {
BRow,
BCol,
BCard,
BCardHeader,
BCardBody,
vSelect,
BButton,
BFormInput,
BFormFile,
BMedia,
BMediaBody,
BMediaAside,
BLink,
BCardText,
BImg,
BFormTextarea,
BFormDatepicker,
BFormTimepicker,
BFormCheckboxGroup,
BTab,
BTabs,
},
data() {
return {
  error: {},
  selected: [],
    optionsBTD: [
      { item: 'Memulihkan Nama Baik Terlapor', name: 'Memulihkan Nama Baik Terlapor' },
      { item: 'Meneruskan Kepada Mahkamah Agung', name: 'Meneruskan Kepada Mahkamah Agung' },
      { item: 'Terdapat Disenting Opinion', name: 'Terdapat Disenting Opinion' },
      { item: 'Terdapat Concurring Opinion', name: 'Terdapat Concurring Opinion' },
    ],
    optionsBTD2: [
      { item: 'Teguran Lisan', name: 'Teguran Lisan' },
      { item: 'Teguran Tertulis', name: 'Teguran Tertulis' },
      { item: 'Pernyataan tidak puas secara tertulis', name: 'Pernyataan tidak puas secara tertulis' },
      { item: 'Penundaan Kenaikan Gaji Berkala paling lama 1 tahun', name: 'Penundaan Kenaikan Gaji Berkala paling lama 1 tahun' },
      { item: 'Penurunan gaji sebesar 1 kali kenaikan gaji berkala paling lama 1 tahun', name: 'Penurunan gaji sebesar 1 kali kenaikan gaji berkala paling lama 1 tahun' },
      { item: 'Penundaan kenaikan pangkat paling lama 1 tahun', name: 'Penundaan kenaikan pangkat paling lama 1 tahun' },
      { item: 'Hakim non-palu paling lama 6 bulan', name: 'Hakim non-palu paling lama 6 bulan' },
      { item: 'Pembebasan dari jabatan struktural', name: 'Pembebasan dari jabatan struktural' },
      { item: 'Hakim non-palu lebih dari 6 bulan sampai dengan 2 tahun', name: 'Hakim non-palu lebih dari 6 bulan sampai dengan 2 tahun' },
      { item: 'Pemberhentian sementara', name: 'Pemberhentian sementara' },
      { item: 'Pemberhentian tetap dengan hak pensiun', name: 'Pemberhentian tetap dengan hak pensiun' },
      { item: 'Pemberhentian tetap tidak dengan hormat', name: 'Pemberhentian tetap tidak dengan hormat' },
      { item: 'Meneruskan kepada mahkamah agung', name: 'Meneruskan kepada mahkamah agung' },
      { item: 'Terlapor telah dijatuhi sanksi atas kasus yang sama', name: 'Terlapor telah dijatuhi sanksi atas kasus yang sama' },
      { item: 'Terlapor sudah pensiun atau meninggal dunia', name: 'Terlapor sudah pensiun atau meninggal dunia' },
      { item: 'Terdapat Disenting Opinion', name: 'Terdapat Disenting Opinion' },
      { item: 'Terdapat Concurring Opinion', name: 'Terdapat Concurring Opinion' },
    ],
    optionsBTD3: [
      { item: 'Laporan di Tutup', name: 'Laporan di Tutup' },
    ],
    optionsBD: [
      { item: 'Pembebasan dari jabatan struktural', name: 'Pembebasan dari jabatan struktural' },
      { item: 'Hakim non-palu lebih dari 6 bulan sampai dengan 2 tahun', name: 'Hakim non-palu lebih dari 6 bulan sampai dengan 2 tahun' },
      { item: 'Pemberhentian sementara', name: 'Pemberhentian sementara' },
      { item: 'Pemberhentian tetap dengan hak pensiun', name: 'Pemberhentian tetap dengan hak pensiun' },
      { item: 'Pemberhentian tetap tidak dengan hormat', name: 'Pemberhentian tetap tidak dengan hormat' },
      { item: 'Meneruskan kepada mahkamah agung', name: 'Meneruskan kepada mahkamah agung' },
      { item: 'Terlapor telah dijatuhi sanksi atas kasus yang sama', name: 'Terlapor telah dijatuhi sanksi atas kasus yang sama' },
      { item: 'Terlapor sudah pensiun atau meninggal dunia', name: 'Terlapor sudah pensiun atau meninggal dunia' },
    ]
}
},
directives: {
Ripple,
},
props: {
pihak: {},
negaraOptions: {},
provinsiOptions: {},
kotaOptions: {},
penetapanOptions: {},
komisionerOptions: {},
localData: {},
localDatas: {},
dataPutusanPleno: {},
showDisenting: false,
showConcurring: false,
},
methods: {
  hasDissentingOpinion(kesimpulanArray) {
    kesimpulanArray.forEach(element => {
      console.log(element)
      if(element == "Terdapat Disenting Opinion")
      {
        this.showDisenting = true
        return true
      } else {
        this.showDisenting = false
        return false
      }
    });
  },
async redirectF(putusan_id) {
  switch (putusan_id) {
  case 1:
    this.$router.push('/tpl-pemeriksaan-lanjutan');
    break;

  case 2:
    this.$router.push('/pleno-daftar-tunggu');
    break;

  case 3:
    this.$router.push('/tpl-pemeriksaan-pendahuluan');
    break;

  case 10:
    this.$router.push('/tpl-pemeriksaan-pendahuluan');
    break;
}

},
async update(params) {
  switch (params) {
    case 'pemeriksaan-lanjutan':
      this.dataPutusanPleno.jenis_cta = "pemeriksaan-lanjutan";
      break;
  }
  
  const response = await updateVerifikasi(this.localData)
  if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Panel Jadwal',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$router.push({name: 'panel-jadwal'})
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Panel Jadwal',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
},
async download(filename){
  const result = await downloadFilePanelJadwal(filename)
  const url = window.URL.createObjectURL(new Blob([result.data]))
  const link = document.createElement('a')
  const date = new Date().toLocaleString(['ban', 'id'])
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
},
async updateTindakan(index)
{
  if(this.dataPutusanPleno[index].file !== undefined)
    {
      const responseUpload = await uploadFilePanelJadwal(this.dataPutusanPleno[index].file, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
      this.dataPutusanPleno[index].tanda_tangan = responseUpload.data.filename
    }

    const response = await updateJadwalPlenoPutusan(this.dataPutusanPleno[index])
    console.log(response)
    if(response.data.success === true)
    {
      this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Tindakan',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: this.dataPutusanPleno[index].no_register + ' ' + response.data.message,
          },
        })
        this.$bvModal.hide(`modal_konfirmasi_no_registrasi_tindakan_${index}`)
        // switch (this.dataPutusanPleno[index].putusan_id) {
        //   case 1:
        //     this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
        //     break;

        //   case 2:
        //     this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
        //     break;

        //   case 3:
        //     this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
        //     break;

        //   case 10:
        //     this.$bvModal.show(`modal_konfirmasi_redirect_${index}`)
        //     break;
        // }
        
    }
    else
    {
      this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Tindakan',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.dataPutusanPleno[index].no_register + ' ' + response.data.message,
          },
        })
        this.error = response.data.error
    }
},
 onFileChangeLogo(val) {
  if(val !== null)
  {
    this.localData.logo = URL.createObjectURL(val)
  }
},
changeUPT(value) {
  this.$emit('emitReceiverUPT', value)
  this.$emit('update:kanwilFilter', value)
},
},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
